export const configColumns = [
  {
    key: 'no',
    label: 'No',
    class: 'p-1 text-black',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'name',
    label: 'Nama',
    class: 'p-1 text-black',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'total_transaction',
    label: 'jumlah transaksi',
    class: 'p-1 text-black',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'join_date',
    label: 'Tanggal Bergabung',
    class: 'p-1 text-black',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'total_member',
    label: 'Total Member',
    class: 'p-1 text-black',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '14px',
      color: 'black',
    },
  },
  {
    key: 'total_commission',
    label: 'Total Komisi',
    sortable: true,
    class: 'p-1 text-black',
    thStyle: {
      textTransform: 'uppercase',
      fontSize: '14px',
      color: 'black',
    },
  },
]

export const dateRangeItems = [
  {
    label: 'Jan - Feb',
    value: [0, 1, new Date().getFullYear()],
  },
  {
    label: 'Mar - Apr',
    value: [2, 3, new Date().getFullYear()],
  },
  {
    label: 'Mei - Jun',
    value: [4, 5, new Date().getFullYear()],
  },
  {
    label: 'Jul - Agu',
    value: [6, 7, new Date().getFullYear()],
  },
  {
    label: 'Sep - Okt',
    value: [8, 9, new Date().getFullYear()],
  },
  {
    label: 'Nov - Des',
    value: [10, 11, new Date().getFullYear()],
  },
]
