<template>
  <div>
    <BCard class="wrapper-data-affiliator">
      <BRow class="align-items-center justify-content-between mb-2 px-2">
        <BCol cols="auto d-flex">
          <div class="text-black mb-0 mr-1 text-2xl font-semibold">
            Leaderboard Affiliator
          </div>
          <div>
            <div>
              <BButton
                id="popoverService"
                v-model="serviceName"
                class="btn-smd-flex w-40 justify-between items-center mr-1"
                type="button"
                aria-expanded="false"
                size="small"
                variant="outline-secondary"
                style="border-color: #e2e2e2 !important; height: 31px; padding-top: 5px;"
              >
                <div class="d-flex alig-items-center ml-[-6px]">
                  <img
                    :src="selectedImage || 'https://storage.googleapis.com/komerce/assets/svg/icon-komship.svg'"
                    width="20px"
                    alt=""
                  >
                  <strong class="ms-2 mt-[2px] text-black">{{ selectedValue || "Komship" }}</strong>
                </div>
              </BButton>
              <BPopover
                id="popover-filter-type"
                target="popoverService"
                triggers="focus"
                placement="bottom"
                style="padding: 0px !important"
              >
                <ul style="margin: -8px -10px -8px -10px;">
                  <li>
                    <BButton
                      class="d-flex align-items-center h-10"
                      variant="flat-dark"
                      style="padding-left: 0px; padding-right: 10px; width: 120px;"
                      @click="fetchKomship();selectedImage =
                        'https://storage.googleapis.com/komerce/assets/svg/icon-komship.svg'"
                    >
                      <img
                        :src="'https://storage.googleapis.com/komerce/assets/svg/icon-komship.svg'"
                        width="20px"
                        alt="icon"
                      >
                      <strong class="ms-2">Komship</strong>
                    </BButton>
                  </li>
                  <li>
                    <BButton
                      class="d-flex align-items-center h-10"
                      variant="flat-dark"
                      style="
                        padding-left: 0px;
                        padding-right: 10px;
                        width: 120px;
                      "
                      @click="
                        fetchBoostr()
                        selectedImage =
                          'https://storage.googleapis.com/komerce/assets/logo/Logo-Komplace-main.svg'
                      "
                    >
                      <img
                        :src="'https://storage.googleapis.com/komerce/assets/logo/Logo-Komplace-main.svg'"
                        width="18px"
                        alt="icon"
                      >
                      <strong class="ms-2">Komplace</strong>
                    </BButton>
                  </li>
                  <li>
                    <BButton
                      class="d-flex align-items-center h-10"
                      variant="flat-dark"
                      style="
                        padding-left: 0px;
                        padding-right: 10px;
                        width: 120px;
                      "
                      @click="
                        fetchKompack()
                        selectedImage =
                          'https://storage.googleapis.com/komerce/assets/LP-Komerce/kompack.svg'
                      "
                    >
                      <img
                        :src="'https://storage.googleapis.com/komerce/assets/LP-Komerce/kompack.svg'"
                        width="18px"
                        alt="icon"
                      >
                      <strong class="ms-2">Kompack</strong>
                    </BButton>
                  </li>
                  <li>
                    <BButton
                      class="d-flex align-items-center h-10"
                      variant="flat-dark"
                      style="
                        padding-left: 0px;
                        padding-right: 10px;
                        width: 120px;
                      "
                      @click="
                        fetchKomcard()
                        selectedImage =
                          'https://storage.googleapis.com/komerce/assets/LP-Komerce/komcards.svg'
                      "
                    >
                      <img
                        :src="'https://storage.googleapis.com/komerce/assets/LP-Komerce/komcards.svg'"
                        width="18px"
                        alt="icon"
                      >
                      <strong class="ms-2">Komcards</strong>
                    </BButton>
                  </li>
                </ul>
              </bpopover></div>
          </div>
        </BCol>

        <BCol cols="auto d-flex align-items-center">
          <div
            id="popoverDateRange"
            class="wrapper-filter-date-range p-50 mr-1 cursor-pointer"
          >
            <span class="text-black"> {{ labelDateRange }} </span>
          </div>
          <b-popover
            target="popoverDateRange"
            triggers="hover"
            placement="bottom"
            custom-class="wrapper-date-range"
          >
            <b-row class="align-items-center justify-content-between mb-2">
              <b-img
                class="cursor-pointer"
                src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/arrow-left-2.svg"
                @click="decrement"
              />
              <span class="text-black"> {{ yearDateRange }} </span>
              <b-img
                class="cursor-pointer"
                src="https://storage.googleapis.com/komerce/assets/icons/arrow-right-2.svg"
                @click="increment"
              />
            </b-row>

            <b-row
              v-for="(item, index) of dateRangeItem"
              :key="index + 1"
              class="justify-content-center"
            >
              <div
                :class="
                  currentFilter[0] === item.value[0]
                    ? 'wrapper-month-active mb-1 mx-1 p-50 text-center cursor-pointer'
                    : 'wrapper-month mb-1 mx-1 p-50 text-center cursor-pointer'
                "
                style="border-radius: 8px; width: 100%"
                @click="
                  dateRange(
                    item.value[0],
                    item.value[1],
                    yearDateRange,
                    item.label
                  )
                "
              >
                <span>{{ item.label }}</span>
              </div>
            </b-row>
          </b-popover>

          <div>
            <BInputGroup class="wrapper-search">
              <template #prepend>
                <BInputGroupText>
                  <b-img
                    src="https://storage.googleapis.com/komerce/assets/komerce-icon/Hitam/search-normal-1.svg"
                  />
                </BInputGroupText>
              </template>
              <BFormInput
                v-model="search"
                placeholder="Cari nama afiliator"
                @input="fetchLeaderboard"
              />
            </BInputGroup>
          </div>
        </BCol>
      </BRow>

      <BOverlay
        id="infinite-list"
        variant="light"
        :show="loading"
        spinner-variant="primary"
        blur="0"
        opacity=".5"
        rounded="sm"
      >
        <BTable
          striped
          show-empty
          empty-text="Tidak ada data untuk ditampilkan."
          :items="items"
          :fields="fields"
          :tbody-tr-class="rowClass"
        >
          <template #cell(no)="data">
            <div
              v-if="data.value === 1"
              class="first-affiliator d-flex justify-content-center align-items-center"
            >
              <span>
                <strong>
                  {{ data.value }}
                </strong>
              </span>
            </div>
            <div
              v-if="data.value === 2"
              class="second-affiliator d-flex justify-content-center align-items-center"
            >
              <span>
                <strong>
                  {{ data.value }}
                </strong>
              </span>
            </div>
            <div
              v-if="data.value === 3"
              class="third-affiliator d-flex justify-content-center align-items-center"
            >
              <span>
                <strong>
                  {{ data.value }}
                </strong>
              </span>
            </div>
            <div
              v-if="data.value > 3"
              class="ml-1"
            >
              {{ data.value }}
            </div>
          </template>

          <template #cell(name)="data">
            <div v-if="data.item.no > 3">
              {{ data.value }}
            </div>
            <div v-else>
              <strong>
                {{ data.value }}
              </strong>
            </div>
          </template>

          <template #cell(total_transaction)="data">
            <div v-if="data.item.no > 3">
              {{ data.value }}
            </div>
            <div v-else>
              <strong>
                {{ data.value }}
              </strong>
            </div>
          </template>

          <template #cell(join_date)="data">
            <div v-if="data.item.no > 3">
              {{ moment(data.value).format('DD MMMM YYYY') }}
            </div>
            <div v-else>
              <strong>
                {{ moment(data.value).format('DD MMMM YYYY') }}
              </strong>
            </div>
          </template>

          <template #cell(total_member)="data">
            <div v-if="data.item.no > 3">
              {{ data.value }}
            </div>
            <div v-else>
              <strong>
                {{ data.value }}
              </strong>
            </div>
          </template>

          <template #cell(total_commission)="data">
            <div v-if="data.item.no > 3">
              {{ IDR(data.value) }}
            </div>
            <div v-else>
              <strong>
                {{ IDR(data.value) }}
              </strong>
            </div>
          </template>
        </BTable>
      </BOverlay>
    </BCard>
  </div>
</template>

<script>
import { affiliateAxiosIns } from '@/libs/axios'
import moment from 'moment'
import { IDR } from '@/libs/currency'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { configColumns, dateRangeItems } from './config'

export default {
  data() {
    return {
      loading: false,
      search: '',
      items: [],
      fields: configColumns,
      dateRangeItem: dateRangeItems,
      leaderboardFilter: {
        start_month: '',
        end_month: '',
      },
      yearDateRange: new Date().getFullYear(),
      labelDateRange: 'Jan - Feb',
      currentFilter: [0, 1],

      limit: 20,
      offset: 0,

      moment,
      IDR,
      isDefault: true,
      isKomship: false,
      isBoostr: true,
      isKompack: false,
      isKomcard: false,
      selectedValue: '',
      selectedImage: '',
      serviceName: '' || 'komship',
    }
  },
  mounted() {
    const listElm = document.getElementById('infinite-list')
    listElm.addEventListener('scroll', e => {
      if (Math.ceil(listElm.scrollTop + listElm.clientHeight) >= listElm.scrollHeight && !this.loading) {
        this.nextData()
      }
    })
    this.dateRange(
      this.currentFilter[0],
      this.currentFilter[1],
      this.yearDateRange,
      this.labelDateRange,
    )
  },
  methods: {
    fetchLeaderboard() {
      this.offset = 0
      this.loading = true
      const params = `service_name=${this.serviceName}&start_month=${this.leaderboardFilter.start_month}&end_month=${this.leaderboardFilter.end_month}&limits=${this.limit}&offset=${this.offset}&search=${this.search}`
      const url = `/v1/affiliator/admin/leaderboard?${params}`

      affiliateAxiosIns
        .get(url)
        .then(response => {
          this.items = response.data.data
          this.offset += this.items.length
          this.loading = false
        })
    },
    dateRange(firstMonth, secondMonth, year, label) {
      this.labelDateRange = `${label} ${this.yearDateRange}`
      this.currentFilter[0] = firstMonth
      this.currentFilter[1] = secondMonth
      /* eslint-disable no-param-reassign */
      secondMonth += 1
      const firstDate = new Date(year, firstMonth, 1)
      const secondDate = new Date(year, secondMonth, 0)
      const firstDays = []
      const secondDays = []

      while (firstDate.getMonth() === firstMonth) {
        firstDays.push(new Date(firstDate))
        firstDate.setDate(firstDate.getDate() + 1)
      }

      while (secondDate.getMonth() === secondMonth) {
        secondDays.push(new Date(secondDate))
        secondDate.setDate(secondDate.getDate() + 1)
      }

      this.leaderboardFilter.start_month = this.formatDate(firstDate, 'first')
      this.leaderboardFilter.end_month = this.formatDate(secondDate, 'second')
      return this.fetchLeaderboard()
    },
    decrement() {
      /* eslint-disable no-param-reassign */
      this.yearDateRange -= 1
    },
    increment() {
      /* eslint-disable no-param-reassign */
      this.yearDateRange += 1
    },
    rowClass(item) {
      let label = 'upper-row-table'
      if (item?.no > 3 || item === null) label = ''
      return label
    },
    formatDate(date, label) {
      const d = date
      let month = label === 'first' ? `${(d.getMonth())}` : `${(d.getMonth() + 1)}`
      let days = `${d.getDate()}`
      const years = d.getFullYear()

      if (month.length < 2) {
        month = `0${month}`
      }
      if (days.length < 2) {
        days = `0${days}`
      }

      return [years, month, days].join('-')
    },
    nextData() {
      this.loading = true
      const params = `service_name=${this.serviceName}&start_month=${this.leaderboardFilter.start_month}&end_month=${this.leaderboardFilter.end_month}&limits=${this.limit}&offset=${this.offset}&search=${this.search}`
      const url = `/v1/affiliator/admin/leaderboard?${params}`

      affiliateAxiosIns
        .get(url)
        .then(response => {
          const { data } = response.data
          this.items.push(...data)
          this.offset += data.length
          this.loading = false
        }).catch(err => {
          this.loading = false
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err,
              variant: 'danger',
            },
          }, { timeout: 2000 })
        })
    },
    fetchKomship() {
      this.isKomship = true
      this.isDefault = false
      this.selectedValue = 'Komship'
      this.serviceName = 'komship'
      this.fetchLeaderboard()
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
    fetchBoostr() {
      this.isBoostr = true
      this.isDefault = false
      this.selectedValue = 'Komplace'
      this.serviceName = 'boostr'
      this.fetchLeaderboard()
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
    fetchKompack() {
      this.isKompack = true
      this.isDefault = false
      this.selectedValue = 'kompack'
      this.serviceName = 'kompack'
      this.fetchLeaderboard()
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
    fetchKomcard() {
      this.isKomcard = true
      this.isDefault = false
      this.selectedValue = 'komcard'
      this.serviceName = 'komcard'
      this.fetchLeaderboard()
      this.$root.$emit('bv::hide::popover', 'popover-filter-type')
    },
  },
}
</script>

<style>
.wrapper-filter-date-range {
  border: 1px solid #ece9f1;
  border-radius: 8px;
}

.wrapper-search .input-group-prepend .input-group-text {
  border: 1px solid #e2e2e2;
  border-radius: 8px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.wrapper-search .form-control {
  border: 1px solid #e2e2e2;
  border-left: 0;
  border-radius: 8px;
}

.wrapper-data-affiliator .card-body {
  padding-left: 0;
  padding-right: 0;
}

.wrapper-date-range > .popover-body {
  min-width: 250px;
  background: #f8f8f8 !important;
}

.wrapper-month {
  background: white;
  color: black;
}

.wrapper-month:hover {
  background: #f95031;
  color: white;
}

.wrapper-month-active {
  background: #f95031;
  color: white;
}

.upper-row-table {
  background: #ffdf82 !important;
}

.first-affiliator {
  border-radius: 20px;
  background: #ffca09;
  border: 2px solid #f8f8f8;
}

.second-affiliator {
  border-radius: 20px;
  background: #b6b5b8;
  border: 2px solid #f8f8f8;
}

.third-affiliator {
  border-radius: 20px;
  background: #ad8a56;
  border: 2px solid #f8f8f8;
}

#infinite-list {
  height: 100vh;
  overflow-y: scroll;
}
</style>
